import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Table, Button, Tag, Tooltip } from "antd";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import {
  PageComponent,
  Check,
  LongText,
  ActionTable,
  confirmModal,
  CopyText
} from "../../components";

import { ICONS } from "../../config";
import { parseDate } from "../../config/formats";

import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate
} from "../../config/filters";

import CreditDrawer from "./CreditDrawer";
import SubresellerDrawer from "./Drawer";
import InfoDrawer from "./InfoDrawer";

import ResellerConfigDrawer from "./ResellerConfigDrawer";

import "../../styles/components.scss";

export default function SubresellersPage() {
  const { profile } = useSelector((store) => store.profile);

  const translate = useTranslate();

  const SUB_ADD_TAKE_CREDIT =
    profile?.parent_id && profile?.status === "NEED_BUY_PARTNER_CREDITS";

  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(["id", "DESC"]);

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [resellerConfigOpen, setResellerConfigOpen] = useState(false);

  const [onRow, setOnRow] = useState({ show: false, item: null });

  const [search, setSearch] = useState({});
  const [isBranding, setIsBranding] = useState(null);
  const [isBlocked, setIsBlocked] = useState(null);

  const [date, setDate] = useState(null);

  const [dataSource, setDataSource] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [creditsDrawer, setCreditsDrawer] = useState({ show: false, type: "" });

  const columns = [
    {
      width: 60,
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      }
    },
    {
      title: translate("Brand name"),
      dataIndex: "brand_name",
      key: "brand_name",
      fixed: "left",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },

    {
      title: translate("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record.name ? <LongText children={record.name} /> : "N/A",
    },
    {
      title: translate("Surname"),
      dataIndex: "surname",
      key: "surname",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record.surname ? <LongText children={record.surname} /> : "N/A",
    },

    {
      title: translate("Email"),
      dataIndex: "email",
      key: "email",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record.email ? (
          <CopyText text={<LongText children={record.email} />} />
        ) : (
          "N/A"
        ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      sorter: true
    },

    {
      title: translate("Devices count"),
      dataIndex: "device_count",
      key: "device_count",
      align: "center",
      sorter: true
    },

    {
      title: translate("Status"),
      dataIndex: "blocked",
      key: "blocked",
      align: "center",
      filters: [
        {
          text: "Active",
          value: false
        },
        {
          text: "Block",
          value: true
        }
      ],
      render: (text, record, index) => {
        return record.blocked ? (
          <Tag color="red">Block</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        );
      }
    },
    {
      title: translate("Branding"),
      dataIndex: "branding",
      key: "branding",
      align: "center",
      filters: [
        {
          text: "YES",
          value: true
        },
        {
          text: "NO",
          value: false
        }
      ],
      render: (text, record, index) => <Check check={record.branding} />
    },

    {
      title: translate("Updated date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      sorter: true,
      ...getColumnDateProps((e, dateString) => setDate(dateString)),
      render: (text, record, index) => parseDate(text),
    },

    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      ...getColumnDateProps((e, dateString) => setDate(dateString)),
      render: (text, record, index) => parseDate(text),
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      align: "center",
      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ActionTable
            handleMenuClick={(e) => handleMenuClick(e, record)}
            buttons={[
              record.approved && {
                key: "edit",
                label: translate("Edit"),
                icon: ICONS.EDIT
              },
              record.approved && {
                key: "block",
                label: record.blocked
                  ? translate("Unblock")
                  : translate("Block"),
                icon: record.blocked ? ICONS.UNBLOCK : ICONS.BLOCK
              },

              (SUB_ADD_TAKE_CREDIT || profile?.is_seller) && {
                key: "add_credits",
                label: "Add Credits",
                icon: ICONS.PLUS
              },

              (SUB_ADD_TAKE_CREDIT || profile?.is_seller) && {
                key: "take_credits",
                label: "Take Credits",
                icon: ICONS.MINUS
              },

              !record.approved && {
                key: "approve",
                label: translate("Approve"),
                icon: ICONS.TRUE
              },
              !record.approved && {
                key: "cancel",
                label: translate("Cancel"),
                icon: ICONS.FALSE
              }
            ]}
          />
        </div>
      )
    }
  ];

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrentData(item);
        setIsOpen(true);
        break;
      case "block":
        confirmModal({
          title: `${translate("Are you sure you want to")} ${
            item.blocked ? translate("unblock") : translate("block")
          } ?`,
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => blockItem(item.id)
        });
        break;
      case "add_credits":
        setCreditsDrawer({
          show: true,
          type: "Add Credits",
          item: item
        });
        break;
      case "take_credits":
        setCreditsDrawer({
          show: true,
          type: "Take Credits",
          item: item
        });
        break;
      case "approve":
        confirmModal({
          title: translate(
            "Are you sure you want to approve this sub-reseller"
          ),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => approve({ id: item.id, approved: true })
        });
        break;
      case "cancel":
        confirmModal({
          title: translate("Are you sure you want to cancel this sub-reseller"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => approve({ id: item.id, approved: false })
        });
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);

    if (filters["branding"]) {
      setIsBranding(filters["branding"]);
    } else {
      setIsBranding(null);
    }

    if (filters["blocked"]) {
      setIsBlocked(filters["blocked"]);
    } else {
      setIsBlocked(null);
    }

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
  };

  const getData = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort: sort
    };

    if (search.brand_name) {
      if (!query.search) {
        query.search = {};
      }
      query.search["brand_name"] = search.brand_name[0];
    }
    if (search.name) {
      if (!query.search) {
        query.search = {};
      }
      query.search["name"] = search.name[0];
    }
    if (search.surname) {
      if (!query.search) {
        query.search = {};
      }
      query.search["surname"] = search.surname[0];
    }
    if (search.email) {
      if (!query.search) {
        query.search = {};
      }
      query.search["email"] = search.email[0];
    }
    if (query.search) {
      query.search = query.search;
    }

    const queryDate = getQueryDate(date);

    if (queryDate) query.between = queryDate;

    if (isBranding || isBlocked) {
      if (isBranding) {
        query["filter"] = {
          branding: isBranding
        };
      }

      if (isBlocked) {
        query["filter"] = {
          blocked: isBlocked
        };
      }
    } else {
      delete query.filter;
    }

    REQUESTS.SUBRESELLERS.GET({ query: JSON.stringify(query) })
      .then((data) => {
        const sortApproved = data?.rows;

        sortApproved?.sort((a, b) => {
          if (!a.approved && b.approved !== false) {
            return -1;
          }
          if (!b.approved && a.approved !== false) {
            return 1;
          }
          return 0;
        });

        setDataSource(sortApproved);
        setLimit(data.limit);
        setCurrentPage(data.page);
        setTotal(data.total);
        setLoading(false);

        if (data.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const approve = (body) => {
    REQUESTS.SUB_RESELLERS_APPROVE(body).then(getData);
  };

  const blockItem = (id) => {
    REQUESTS.SUBRESELLERS.BLOCK({
      id
    }).then(getData);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      getData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentPage, limit, sort, search, isBranding, isBlocked, date]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentData(null);
    }
    if (!onRow) {
      setOnRow({ show: false, item: null });
    }
  }, [isOpen, onRow]);

  return (
    <PageComponent>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10
        }}
      >
        <h3>{translate("Subresellers")}</h3>

        <div>
          <div className="tow-actions-btn">  
            <Button
              disabled={
                profile?.is_partner ||
                (profile?.parent_id && !profile?.is_partner)
                  ? false
                  : true
              }
              className="plus-button"
              icon={<PlusOutlined />}
              onClick={() => setIsOpen(true)}
            >
              {translate("Add New Subreseller")}
            </Button>
            {(profile?.is_partner ||
              (profile?.parent_id && !profile?.is_partner)) && (
              <Tooltip placement="bottom" title={translate("Reseller Config")}>
                <Button
                  disabled={
                    profile?.is_partner ||
                    (profile?.parent_id && !profile?.is_partner)
                      ? false
                      : true
                  }
                  className="plus-button"
                  icon={<SettingOutlined />}
                  onClick={() => setResellerConfigOpen(true)}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <Table
        rowClassName={(record, index) => {
          if (record.blocked) {
            return "table-row-red";
          }
        }}
        size="small"
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true
        }}
        rowKey="id"
        scroll={{
          x: "max-content",
          y: null
        }}
        onRow={(record, rowIndex) => {
          return {
            className: "active-row",
            onClick: (event) => {
              setOnRow({ show: true, item: record });
            }
          };
        }}
      />

      <CreditDrawer
        open={creditsDrawer}
        onClose={() => setCreditsDrawer({ show: false, type: "" })}
        getData={getData}
        by={SUB_ADD_TAKE_CREDIT && !profile?.is_partner ? "by sub" : ""}
      />

      <SubresellerDrawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        getData={getData}
        currentData={currentData}
      />

      <InfoDrawer
        open={onRow}
        onClose={() => setOnRow({ show: false, item: null })}
      />
      {(profile?.is_partner ||
        (profile?.parent_id && !profile?.is_partner)) && (
        <ResellerConfigDrawer
          open={resellerConfigOpen}
          onClose={() => setResellerConfigOpen(false)}
        />
      )}
    </PageComponent>
  );
}
