import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setIsRefreshBillingHistory } from "../../store/features/profileSlice";

import { Table, Tag } from "antd";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import { ICONS } from "../../config";
import { parseFullDate } from "../../config/formats";
import {
  getColumnSearchProps,
  getQueryDate,
  getColumnDateProps,
} from "../../config/filters";

import { PageComponent, TableCSV, LongText } from "../../components";

import "../../styles/billing.scss";

export default function BillingPage() {

  const translate = useTranslate();
  const dispatch = useDispatch();

  const { isRefreshBillingHistory, profile } = useSelector(
    (state) => state.profile
  );

  const needBuy =
    profile?.parent_id && profile?.status === "NEED_BUY_PARTNER_CREDITS";

  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState(null);

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    paymentMethod: null,
    status: null,
  });

  const paymentMethodFilter = [
    { text: "Activation",  value: "activation" },
    { text: "By admin",  value: "by_admin" },
    { text: "Paypal",  value: "paypal" },
    { text: "By provider",  value: "by_provider" },
    { text: "Dlocal",  value: "dlocal" },
    { text: "Coinbase", value: "coinbase" },
    { text: "Ineco", value: "ineco" },
    { text: "Ameria", value: "ameria" },
  ];

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "index",
      key: "index",
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: translate("Transaction ID"),
      dataIndex: "transaktion_id",
      key: "transaktion_id",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
      record?.transaktion_id  ? (
          <LongText children={record?.transaktion_id} />
        ) : (
          "N/A"
        ),
    },

    {
      title: translate("Status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        {
          text: translate("COMPLETED"),
          value: "COMPLETED",
        },
        {
          text: translate("PENDING"),
          value: "PENDING",
        },
      ],
      render: (text, record, index) =>
        record.status == "COMPLETED" ? (
          <Tag color="green">{record.status}</Tag>
        ) : (
          <Tag color="gold">{record.status}</Tag>
        ),
    },

    {
      title: translate("Credits"),
      dataIndex: "credit",
      key: "credit",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        if (record.credit > 0 && record.payment_method !== "activation") {
          return (
            <p className="arrow">
              <span> {record.credit}</span>
              <span style={{ color: "green" }}>{ICONS.DOWN}</span>
            </p>
          );
        }
        if (record.credit <= 0 || record.payment_method == "activation") {
          return (
            <p className="arrow">
              <span> {record.credit}</span>
              <span style={{ color: "red" }}>{ICONS.UP}</span>
            </p>
          );
        }
      },
    },

    profile?.is_partner
      ? {}
      : {
          title: translate("Payment method"),
          dataIndex: "payment_method",
          key: "payment_method",
          align: "center",
          filters: needBuy
            ? paymentMethodFilter?.slice(0, 2)
            : paymentMethodFilter,
          render: (text, record, index) => {
            let str = record.payment_method.replace("_", " ");
            return str.charAt(0).toUpperCase() + str.slice(1);
          },
        },

    {
      title: translate("Amount"),
      dataIndex: "amount",
      key: "amount",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        const currency = record.currency ? record.currency : "";

        return (
          <span>{record.amount ? record.amount + " " + currency : "N/A"}</span>
        );
      },
    },

    {
      title: translate("Description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text, record, index) =>
        record.description ? <LongText children={record.description} /> : "N/A",
    },

    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      status: filters["status"] || null,
      paymentMethod: filters["payment_method"] || null,
    }));

    if (filters["transaktion_id"]) {
      setSearch(filters);
    } else {
      setSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };


  const getBillingHistory = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }
      if (search.transaktion_id) {
        query["search"]["transaktion_id"] = search.transaktion_id[0];
      }
    } else {
      delete query.search;
    }

    const queryDate = getQueryDate(filtersTb.date);

    if (queryDate) {
      query.between = queryDate;
    }

    if (filtersTb.paymentMethod || filtersTb.status) {
      query["filter"] = {};

      if (filtersTb.paymentMethod) {
        query["filter"]["payment_method"] = filtersTb.paymentMethod;
      }

      if (filtersTb.status) {
        query["filter"]["status"] = filtersTb.status;
      }
    } else {
      delete query.filter;
    }

    REQUESTS.BILLING_HISTORY({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setTableData(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
        dispatch(setIsRefreshBillingHistory(false));
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getBillingHistory();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, filtersTb, currentPage, limit, sort, isRefreshBillingHistory]);

  return (
    <PageComponent>
      <div className="head-page">
        <h3>{translate("Billing history")}</h3>
        {tableData && <TableCSV dataForExport={tableData} />}
      </div>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={tableData}
        onChange={handleTableChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{
          x: "max-content",
          y: null,
        }}
      />
    </PageComponent>
  );
}
