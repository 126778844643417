import React, { useState } from "react";
import { SketchPicker } from "react-color";

import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Collapse, Upload, Form, InputNumber } from "antd";

import useTranslate from "../../hooks/translator";

import IMAGES from "../../config/images";

import AppBackgrounds from "./AppBackgrounds";

const { Panel } = Collapse;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function Collaps({
  primaryColor,
  secondaryColor,
  setPrimaryColor,
  setSecondaryColor,
  selectedBg,
  setSelectedBg,
  setLogo,
  setBackground,
  background,
  logo,
}) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);

  const changeBackground = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setBackground(url);
        setSelectedBg(null);
        setLoading(false);
      });
    }
  };

  const changeLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setLogo(url);
      });
    }
  };

  return (
    <Collapse style={{ width: "300px" }} accordion>
      <Panel header={translate("Upload Logo")} key="1" forceRender={true}>
        <div
          style={{
            padding: "7px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form.Item name="logo">
            <Upload
              accept="image/*"
              customRequest={({ file, onSuccess }) => {
                setTimeout(() => {
                  onSuccess("ok");
                }, 0);
              }}
              listType="picture-card"
              showUploadList={false}
              maxCount={1}
              onChange={changeLogo}
            >
              {logo ? (
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "100%",
                  }}
                  onError={(e) => (e.target.src = IMAGES.ERROR)}
                />
              ) : (
                <div>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {translate("Upload")}
                  </div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item name="logo_size" label={translate("Logo size")}>
            <InputNumber />
          </Form.Item>
        </div>
      </Panel>
      <Panel header={translate("Upload Background")} key="3" forceRender={true}>
        <div style={{ padding: "7px 20px" }}>
          <Form.Item name="background_image">
            <Upload
              accept="image/*"
              customRequest={({ file, onSuccess }) => {
                setTimeout(() => {
                  onSuccess("ok");
                }, 0);
              }}
              listType="picture-card"
              maxCount={1}
              onChange={changeBackground}
              showUploadList={false}
            >
              {background ? (
                <img
                  src={background}
                  alt="Background"
                  style={{
                    width: "100%",
                  }}
                  onError={(e) => (e.target.src = IMAGES.ERROR)}
                />
              ) : (
                <div>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {translate("Upload")}
                  </div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <AppBackgrounds
            selectedBg={selectedBg}
            setSelectedBg={setSelectedBg}
          />
        </div>
      </Panel>
      <Panel header={translate("Primary color")} key="4">
        <div style={{ padding: "7px 20px" }}>
          <SketchPicker
            color={primaryColor}
            onChange={(color) => setPrimaryColor(color.hex)}
          />
        </div>
      </Panel>
      <Panel header={translate("Secondary color")} key="5">
        <div style={{ padding: "7px 20px" }}>
          <SketchPicker
            color={secondaryColor}
            onChange={(color) => setSecondaryColor(color.hex)}
          />
        </div>
      </Panel>
    </Collapse>
  );
}
