import { Button, Table, message } from "antd";
import React, { useState, useEffect } from "react";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ICONS } from "../../config";
import { parseFullDate } from "../../config/formats";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../config/filters";

import { confirmModal, CopyText } from "../../components";

export default function BlockedDevices({ activeTab, getProfile }) {
  const translate = useTranslate();

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState({
    deviceId: null,
    deviceKey: null,
    deviceOs: null,
  });

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    state: null,
    updatedAt: null,
  });

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (activeTab == "Blocked devices") {
        getBlackList();
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search, filtersTb, currentPage, activeTab]);

  const columns = [
    {
      width: 60,
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },

    {
      title: translate("Device OS"),
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) => text,
    },

    {
      title: translate("Blocked date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },

    {
      title: translate("Updated date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "updatedAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, updatedAt: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },

    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div className="unblock-device">
          <Button
            icon={ICONS.UNBLOCK}
            onClick={() => handleMenuClick(record, "unblock")}
          >
            {translate("Unblock")}
          </Button>
        </div>
      ),
    },
  ];

  const getBlackList = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }

      if (search.deviceKey) {
        query.search["device_key"] = search.deviceKey[0];
      }
      if (search.deviceOs) {
        query.search["device_os"] = search.deviceOs[0];
      }
    } else {
      delete query.search;
    }

    const queryDate = getQueryDate(filtersTb.date);
    const queryUpdatedAtDate = getQueryDate(filtersTb.updatedAt);

    if (queryDate) {
      query.between = queryDate;
    }

    if (queryUpdatedAtDate) {
      query.between = queryUpdatedAtDate;
    }

    REQUESTS.DEVICE_BLACK_LIST.GET({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setDataSource(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
        if (data.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleMenuClick = (item, key) => {
    switch (key) {
      case "unblock":
        confirmModal({
          title: translate("Do you want to unblock this device"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => {
            REQUESTS.DEVICE_BLACK_LIST.DELETE({ id: item?.id })
              .then((res) => {
                message.success(res);
                getBlackList();
                getProfile();
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSortedInfo(sorter);

    setSearch((prevSearch) => ({
      ...prevSearch,
      deviceOs: filters["device_os"] || null,
      deviceKey: filters["device_key"] || null,
    }));

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  };

  return (
    <Table
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onChange={handleTableChange}
      size="small"
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
      }}
      scroll={{
        x: "max-content",
        y: null,
      }}
    />
  );
}
