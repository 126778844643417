import React, { useState, useEffect } from "react";
import { Table, Image } from "antd";
import REQUESTS from "../../api/requests";

import { IMAGES } from "../../config";
import { parseFullDate } from "../../config/formats";

import {
  getColumnSearchProps,
  getQueryDate,
  getColumnDateProps,
} from "../../config/filters";
import { LongText, CopyText } from "../../components";

export default function MoviesPage({ translate, rowSelection, get }) {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortedInfo, setSortedInfo] = useState({});

  const [search, setSearch] = useState({
    byId: null,
    name: null,
    deviceKey: null,
    deviceOs: null,
  });

  const [filtersTb, setFiltersTb] = useState({
    date: null,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      getData();
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, filtersTb, currentPage, limit, sort, get]);

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "index",
      key: "index",
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },
    {
      title: translate("Poster"),
      dataIndex: "icon",
      key: "icon",
      align: "center",
      width: 75,
      render: (text, record) => {
        return (
          <Image
            src={record?.icon || IMAGES.ERROR}
            alt="icon"
            onError={(e) => (e.target.src = IMAGES.ERROR)}
            preview={record?.icon ? true : false}
            style={{
              width: "47px",
              height: "53px",
              objectFit: "cover",
            }}
          />
        );
      },
    },
    {
      title: translate("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record?.name ?  <CopyText text={<LongText children={text} />} /> : "N/A",
    },

    {
      title: translate("Device OS"),
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) => text,
    },

    {
      title: translate("Movie ID"),
      dataIndex: "media_id",
      key: "media_id",
      align: "center",
      ...getColumnSearchProps(),

      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },
    {
      title: translate("Error count"),
      dataIndex: "count",
      key: "count",
      align: "center",
      sorter: true,
      render: (text, record, index) => text || "N/A",
    },
    {
      title: translate("URL"),
      dataIndex: "url",
      key: "url",
      align: "center",
      render: (text, record, index) =>
        record.url ?  <CopyText text={<LongText children={text} />} /> : "N/A",
    },

    {
      title: translate("Message"),
      dataIndex: "message",
      key: "message",
      align: "center",
      render: (text, record, index) =>
        record.message ? <LongText children={record.message} /> : "N/A",
    },
    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),

    },
  ];

  const getData = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
      filter: { type: "movies" },
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }
      if (search.byId) {
        query.search["id"] = search.byId[0];
      }
      if (search.name) {
        query.search["name"] = search.name[0];
      }
      if (search.deviceKey) {
        query.search["device_key"] = search.deviceKey[0];
      }
      if (search.deviceOs) {
        query.search["device_os"] = search.deviceOs[0];
      }
    } else {
      delete query.search;
    }

    const queryDate = getQueryDate(filtersTb.date);

    if (queryDate) {
      query.between = queryDate;
    }

    REQUESTS.ERROR_LOGS({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setDataList(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setFiltersTb((prevFilters) => ({
      ...prevFilters,
    }));

    setSearch((prevSearch) => ({
      ...prevSearch,
      byId: filters["id"] || null,
      name: filters["name"] || null,
      deviceKey: filters["device_key"] || null,
      deviceOs: filters["device_os"] || null,
    }));

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };
  return (
    <Table
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={dataList}
      onChange={handleTableChange}
      size="small"
      rowSelection={rowSelection}
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
      }}
      scroll={{
        x: "max-content",
        y: null,
      }}
    />
  );
}
