import { useState, useEffect } from "react";

import { Table, Button, message, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import { parseDate } from "../../config/formats";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../config/filters";
import useTranslate from "../../hooks/translator";
import { ICONS } from "../../config";
import {
  PageComponent,
  ActionTable,
  confirmModal,
  CopyText,
  Check,
} from "../../components";

import ServerHostsDrawer from "./DNSDrawer";
import MigrationDrawer from "./MigrationDrawer";

export default function ServerHostPage() {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selected, setSelected] = useState(null);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [sortedInfo, setSortedInfo] = useState({});

  const [search, setSearch] = useState(null);
  const [filtersTb, setFiltersTb] = useState({
    date: null,
    usePort: null,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [migrationOpen, setMigrationOpen] = useState({
    open: false,
    item: null,
  });

  const columns = [
    {
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      width: 40,
      align: "center",
      sort: true,
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: translate("Host"),
      dataIndex: "host",
      key: "host",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },
    {
      title: translate("Devices count"),
      dataIndex: "device_count",
      key: "device_count",
      align: "center",
      render: (text, record, index) => record?.device_count || 0,
    },
    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseDate(text),
    },
    {
      title: translate("Migration"),
      key: "migration",
      align: "center",
      render: (text, record, index) =>
        record.device_count > 0 ? (
          <div className="migration" onClick={() => onMigration(record)}>
            {translate("Migrate")}
          </div>
        ) : (
          <Tooltip title={translate("No devices found on server")}>
            <div className="disabled-txt">{translate("Migrate")}</div>
          </Tooltip>
        ),
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <div onClick={(e) => e.stopPropagation()}>
          <ActionTable
            handleMenuClick={(e) => handleMenuClick(e, record)}
            buttons={[
              {
                key: "edit",
                label: translate("Edit"),
                icon: ICONS.EDIT,
              },

              {
                key: "delete",
                label: translate("Delete"),
                icon: ICONS.DELETE,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  const onMigration = (item) => {
    setMigrationOpen({
      open: true,
      item: item,
    });
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        showDrawer(item);
        break;
      case "delete":
        confirmModal({
          title: translate("Are you sure you want to delete"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => deleteItem(item),
        });
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setFiltersTb((prevFilters) => ({
      ...prevFilters,
    }));
    setSearch(filters);
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getDataList = () => {
    setLoading(true);
    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort: sort,
    };

    if (search?.name) {
      if (!query?.search) {
        query.search = {};
      }
      query.search["name"] = search.name[0];
    }

    if (search?.host) {
      if (!query?.search) {
        query.search = {};
      }
      query.search["host"] = search.host[0];
    }

    if (query?.search) {
      query.search = query.search;
    }

    if (filtersTb.usePort) {
      query["filter"] = {};
    } else {
      delete query.filter;
    }
    //date filters
    const queryDate = getQueryDate(filtersTb.date);

    if (queryDate) query.between = queryDate;

    REQUESTS.SERVER_HOSTS.GET({ query: JSON.stringify(query) })
      .then((data) => {
        if (data) {
          setDataList(data.rows);
          setLimit(data.limit);
          setCurrentPage(data.page);
          setTotal(data.total);
          setLoading(false);

          if (data.rows.length === 0 && currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showDrawer = (selected = null) => {
    setSelected(selected);
    setIsModalOpen(true);
  };

  const deleteItem = (item) => {
    const body = {
      id: Number(item.id),
    };

    REQUESTS.SERVER_HOSTS.DELETE(body)
      .then((mess) => {
        getDataList();
        message.success(translate("DNS deleted successfully"));
      })
      .catch((err) => {
        message.error(err);
      });
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      getDataList();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [currentPage, limit, sort, search, filtersTb]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelected(null);
    }
  }, [isModalOpen]);

  return (
    <PageComponent>
      <div className="head-page">
        <h3>{translate("DNS")}</h3>
        <Button
          className="plus-button"
          icon={<PlusOutlined />}
          onClick={() => setIsModalOpen(true)}
        >
          {translate("Add New DNS")}
        </Button>
      </div>
      <Table
        rowKey="id"
        size={"small"}
        onChange={handleTableChange}
        dataSource={dataList}
        columns={columns}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{
          x: "max-content",
          y: null,
        }}
      />
      <ServerHostsDrawer
        open={isModalOpen}
        setOpen={() => setIsModalOpen(false)}
        selected={selected}
        getData={getDataList}
      />
      {migrationOpen.open && (
        <MigrationDrawer
          selected={migrationOpen}
          onClose={() =>
            setMigrationOpen({
              open: false,
              item: null,
            })
          }
          getData={getDataList}
        />
      )}
    </PageComponent>
  );
}
