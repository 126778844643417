import React from "react";

export default function SubInfoTexts({ info }) {
  return (
    <div
      style={{
        fontSize: "13px",
        color: "#a9a9a9",
        marginTop: "-27px",
        marginBottom: "17px",
        maxWidth: "330px",
        marginLeft: "25px",
        // padding: "0px 20px",
      }}
    >
      {info}
    </div>
  );
}
