import { useEffect, useState } from "react";
import { Button, Form, Drawer, message } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../store/features/profileSlice";

import useTranslate from "../hooks/translator";

import REQUESTS from "../api/requests";

import {
  ButtonComponent,
  InputComponent,
  MessageComponent,
} from "../components";

import ResetProfilePasswordDrawer from "./ResetProfilePasswordDrawer";

export default function EditProfileDrawer({
  visibleProfileDrawer,
  onCloseEditProfileDrawer,
}) {
  const translate = useTranslate();

  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageErr, setMessageErr] = useState(null);

  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profile);

  const [form] = Form.useForm();

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };

  const onChildrenDrawerClose = (form) => {
    setChildrenDrawer(false);
    form.resetFields();
  };
  const onFinish = (values) => {
    const { name, surname, phone_number } = values;

    const body = {
      name,
      surname,
      phone_number,
    };

    REQUESTS.EDIT_PROFILE(body)
      .then((data) => {
        if (data) {
          message.success(data);
          setTimeout(() => {
            getProfile();
            onCloseEditProfileDrawer();
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err === "string") {
          setMessageErr(err);
        }
        if (typeof err.message === "string") {
          setMessageErr(err.message);
        }
      });
  };
  const onFinishFailed = () => {
    setLoading(false);
  };
  const onSubmit = () => {
    setLoading(true);
    setMessageErr("");
    form.submit();
  };
  const keyPressHandler = (e) => {
    if (e.key === "Enter") {
      form.submit();
    }
  };

  useEffect(() => {
    if (visibleProfileDrawer) {
      form.resetFields();

      if (profile?.is_partner) {
        form.setFields([
          {
            name: "partner_key",
            value: profile?.partner_key,
          },
        ]);
      }

      form.setFields([
        {
          name: "brand_name",
          value: profile?.brand_name,
        },
        {
          name: "name",
          value: profile?.name,
        },
        {
          name: "surname",
          value: profile?.surname,
        },
        {
          name: "email",
          value: profile?.email,
        },
        {
          name: "phone_number",
          value: profile?.phone_number,
        },
      ]);
    } else {
      setMessageErr("");
    }
  }, [visibleProfileDrawer]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  return (
    <Drawer
      open={visibleProfileDrawer}
      placement="right"
      onClose={() => onCloseEditProfileDrawer(form)}
      title={translate("Edit profile")}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        onValuesChange={() => setMessageErr("")}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Form.Item
          label={translate("Brand name")}
          name="brand_name"
          extra={translate("Please, contact us in case of any changes")}
        >
          <InputComponent disabled />
        </Form.Item>
        {profile?.is_partner && (
          <Form.Item label={translate("Partner key")} name="partner_key">
            <InputComponent
              disabled={true}
              placeholder={translate("Partner key")}
            />
          </Form.Item>
        )}

        <Form.Item
          label={translate("Name")}
          name="name"
          rules={[
            {
              required: true,
              message: translate("Please input your name!"),
            },
          ]}
        >
          <InputComponent onPressEnter={keyPressHandler} />
        </Form.Item>

        <Form.Item label={translate("Surname")} name="surname">
          <InputComponent onPressEnter={keyPressHandler} />
        </Form.Item>

        <Form.Item label={translate("Email")} name="email">
          <InputComponent disabled={true} />
        </Form.Item>

        <Form.Item label={translate("Phone")} name="phone_number">
          <InputComponent onPressEnter={keyPressHandler} />
        </Form.Item>

        <Button type="link" onClick={showChildrenDrawer} style={{ padding: 0 }}>
          {translate("Reset password")}
        </Button>
        <MessageComponent message={messageErr} />

        <Form.Item>
          <ButtonComponent
            type="primary"
            style={{ marginTop: 20, width: "100%" }}
            onClick={onSubmit}
            loading={loading}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
      <ResetProfilePasswordDrawer
        onChildrenDrawerClose={onChildrenDrawerClose}
        childrenDrawer={childrenDrawer}
      />
    </Drawer>
  );
}
