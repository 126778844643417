import { API_HOST } from "../config/config";

const URLS = {
  // a
  APP_BRANDINGS: `${API_HOST}/provider/app_brandings`,
  ACTIVATION_PACKAGE: `${API_HOST}/provider/provider/activation_packages`,
  ALERTS_MESSAGES: `${API_HOST}/provider/messages`,
  ALERTS_HISTORY: `${API_HOST}/provider/messages/custom_message`,
  ADD_ACTIVATION_DEVICE: `${API_HOST}/provider/provider_devices/add_device`,
  ACTIVATE_DEVICE: `${API_HOST}/provider/provider_devices/activate_device`,
  AVAILABLE_OS: `${API_HOST}/provider/sub_reseller/os`,
  APP_RESET: `${API_HOST}/provider/app_reset`,

  // b
  BILLING_HISTORY: `${API_HOST}/provider/billing_history`,
  BRAND_RESET: `${API_HOST}/provider/app_brandings/brand_reset`,
  BTC: `${API_HOST}/payment/coinbase/charge`,
  // c
  CHANGE_PASSWORD: `${API_HOST}/provider/provider/change_password`,
  COUNTRY_LIST: `${API_HOST}/provider/provider_devices/country_chart`,

  // d
  DEVICES: `${API_HOST}/provider/provider_devices`,
  DEVICES_INFO: `${API_HOST}/provider/provider_devices/devices_info`,
  DEACTIVATE_DEVICE: `${API_HOST}/provider/provider_devices/deactivate_device`,
  DEVICE_UPDATE: `${API_HOST}/provider/provider_devices/update`,
  DEACTIVATED_DEVICES_LIST: `${API_HOST}/provider/provider/device_logs`,
  DEVICE_BLACK_LIST: `${API_HOST}/provider/black_list`,
  // e
  EDIT_PROFILE: `${API_HOST}/provider/provider/edit_profile`,
  ERROR_LOGS: `${API_HOST}/provider/player_error_logs`,
  // f
  FORGOT_PASSWORD: `${API_HOST}/provider/provider/forgot_password`,

  // g
  GEOLOCATION_STATISTICS: `${API_HOST}/provider/provider_devices/country_usage`,
  // h
  // i
  // INECOBANK: 'http://pay.inorain.com/api/ineco/payment_link',
  // j
  // k
  // l
  LOGIN: `${API_HOST}/provider/provider/login`,
  LIVES: `${API_HOST}/provider/statistics/lives`,
  LIVES_CHART: `${API_HOST}/provider/statistics/top_lives`,

  // m
  MOVIES: `${API_HOST}/provider/statistics/movies`,
  MOVIES_CHART: `${API_HOST}/provider/statistics/top_movies`,
  MIGRATE: `${API_HOST}/provider/migration`,
  MULTI_ACCOUNT: `${API_HOST}/provider/multi_account`,

  // n
  // NOTIFICATIONS: `${API_HOST}/provider/notifications`,
  // o
  OTP: `${API_HOST}/provider/two_fa`,

  // p
  PROFILE: `${API_HOST}/provider/provider/profile`,
  PLATFORMS_STATISTICS: `${API_HOST}/provider/platforms/statistics`,
  PACKAGES: `${API_HOST}/provider/packages`,
  PLATFORMS: `${API_HOST}/provider/platforms`,
  PROVIDER_LIMITED: `${API_HOST}/provider/provider/limited`,
  PAYMENT_PIX: `${API_HOST}/payment/dlocal`,
  // q
  // r
  RESET_PASSWORD: `${API_HOST}/provider/provider/reset_password`,
  REGISTERED_CHART: `${API_HOST}/provider/provider_devices/registrated_chart`,
  // s
  SERVER_HOSTS: `${API_HOST}/provider/server`,
  SERVER_HOSTS_FOR_SUB: `${API_HOST}/provider/server/partner`,
  SUBRESELLERS: `${API_HOST}/provider/sub_reseller`,
  SUB_RESELLERS_DEVICES: `${API_HOST}/provider/provider_devices/sub_res_devices`,
  SUB_RESELLERS_APPROVE: `${API_HOST}/provider/sub_reseller/approve`,
  SUB_RESELLER_CREDITS: `${API_HOST}/provider/sub_reseller/credits`,
  SUB_ADD_CREDIT_BY_SUB: `${API_HOST}/provider/sub_reseller/add_credit_by_sub`,
  SUB_TAKE_CREDIT_BY_SUB: `${API_HOST}/provider/sub_reseller/take_credit_by_sub`,
  SETTINGS_CONTACTS: `${API_HOST}/provider/support`,
  SYNC_PLAYLIST: `${API_HOST}/provider/provider_devices/sync_playlist`,
  SUB_RESELLERS_MULTI: `${API_HOST}/provider/sub_reseller/tree`,
  SUB_RESELLER_BILLING: `${API_HOST}/provider/sub_reseller/billing`,
  SUB_DEVICES_COUNT: `${API_HOST}/provider/sub_reseller/devices`,
  // t
  TOKEN_REFRESH: `${API_HOST}/provider/provider/refresh`,
  // u
  // v
  VALIDATE_MAC: `${API_HOST}/provider/provider/validate_mac`
  // w
  // x
  // y
  // z
};

export default URLS;
