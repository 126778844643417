import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { Form, message, Tag, Button } from "antd";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";

import { setBrandingLogo } from "../../store/features/profileSlice";
import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import {
  PageComponent,
  ButtonComponent,
  confirmModal,
  InfoPopover,
} from "../../components";

import TvComponent from "./TvComponent";
import TvSettings from "./TvSettings";
import TvMovies from "./TvMovies";
import LiveTv from "./LiveTv";
import Collaps from "./Collaps";

import { brandingInfoModal } from "./infoBranding";

import "../../styles/brending.scss";

export default function BrandingPage() {
  const [primaryColor, setPrimaryColor] = useState("rgb(32, 32, 32)");
  const [secondaryColor, setSecondaryColor] = useState("#2e844e");
  const [background, setBackground] = useState(null);
  const [selectedBg, setSelectedBg] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoSize, setLogoSize] = useState(null);
  const [packages, setPackages] = useState(null);
  const [isLogoChange, setIsLogoChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const translate = useTranslate();

  const { profile } = useSelector((store) => store.profile);

  const [form] = Form.useForm();

  const defaultBg = useRef(null);

  useEffect(() => {
    if (selectedBg) {
      setBackground(selectedBg);
    }
    if (selectedBg == background) {
      getStaticImageUrl().then((result) => {
        defaultBg.current = result.originFileObj;
      });
    }
  }, [selectedBg, background]);

  const getAppBranding = () => {
    REQUESTS.APP_BRANDINGS.GET()
      .then((data) => {
        setPrimaryColor(data["primary_color"]);
        setSecondaryColor(data["secondary_color"]);
        setLogo(data["logo"]);
        setLogoSize(data["logo_size"]);
        setBackground(data["background_image"]);
        form.setFields([{ name: "logo_size", value: data.logo_size }]);
      })
      .catch((err) => {});
  };

  async function getStaticImageUrl() {
    if (selectedBg == background) {
      const url = background;

      const segments = url?.split("/");
      const filename = segments?.pop();

      // Fetch the image as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Convert Blob to File object
      const file = new File([blob], filename, {
        type: blob.type,
      });

      // Return the file object with the originFileObj property
      return { originFileObj: file };
    } else {
      return;
    }
  }

  const onFinish = (values) => {
    setLoading(true);

    const { background_image, logo, logo_size } = values;

    const formData = new FormData();

    if (logo?.file.originFileObj) {
      formData.append("logo", logo.file.originFileObj);
    }

    if (logo_size && logo_size > 0) {
      formData.append("logo_size", logo_size);
    }

    if (background_image?.file.originFileObj) {
      formData.append("background_image", background_image.file.originFileObj);
    }

    if (selectedBg == background && !background_image?.file.originFileObj) {
      formData.append("background_image", defaultBg.current);
    }

    formData.append("primary_color", primaryColor);

    formData.append("secondary_color", secondaryColor);

    REQUESTS.APP_BRANDINGS.PUT(formData)
      .then((res) => {
        setLoading(false);
        message.success(res);

        setIsLogoChange(!isLogoChange);
        getAppBranding();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const onFieldsChange = (changedField, allFields) => {
    switch (changedField[0].name[0]) {
      case "logo_size":
        setLogoSize(changedField[0].value);
        break;

      default:
        break;
    }
  };

  const onReset = () => {
    confirmModal({
      title: translate("Do you want to reset"),
      okText: translate("Yes"),
      cancelText: translate("Cancel"),
      action: () => {
        REQUESTS.BRAND_RESET()
          .then((res) => {
            message.success(res);
            getAppBranding();
          })
          .catch((err) => {
            message.error(err);
          });
      },
    });
  };

  useEffect(() => {
    dispatch(setBrandingLogo(isLogoChange));
  }, [isLogoChange]);

  useEffect(() => {
    REQUESTS.PROFILE()
      .then((data) => {
        setPackages(data.package);
      })
      .catch((err) => {});
    getAppBranding();
  }, []);

  const onSubmit = () => {
    form.submit();
  };

  return (
    <div style={{ position: "relative", minHeight: "70vh" }}>
      <PageComponent className="branding-page">
        <div className="head-page">
          <h3>
            {translate("Branding")}
            <InfoPopover content={brandingInfoModal()} title={null} />
          </h3>
        </div>

        <div className="content" style={{ position: "relative" }}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
            style={{ width: "300px" }}
            className="branding-settings"
          >
            <Collaps
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              setPrimaryColor={setPrimaryColor}
              selectedBg={selectedBg}
              setSecondaryColor={setSecondaryColor}
              setLogo={setLogo}
              setBackground={setBackground}
              background={background}
              setSelectedBg={setSelectedBg}
              logo={logo}
              setLogoSize={setLogoSize}
              logoSize={logoSize}
            />

            <ButtonComponent
              type="primary"
              onClick={onSubmit}
              disabled={!profile?.branding ? true : false}
              loading={loading}
              style={{
                marginTop: "15px",
                width: "100%",
                color: "#fff",
              }}
            >
              {translate("Save")}
            </ButtonComponent>

            <Button
              onClick={onReset}
              style={{
                marginTop: "15px",
                width: "100%",
                color: "#fff",
                border: "none",
                background: "#ff7875",
              }}
              disabled={!profile?.branding ? true : false}
            >
              {translate("Reset")}
            </Button>

            {!profile?.branding && (
              <Tag
                color="gold"
                style={{ width: "100%", textAlign: "center", marginTop: 10 }}
              >
                {translate("You cannot use branding")}
              </Tag>
            )}
          </Form>

          <div className="tv-swiper">
            <Swiper
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <TvComponent
                  is_branding={packages?.is_branding}
                  logo={logo}
                  logoSize={logoSize}
                  background={background}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              </SwiperSlide>
              <SwiperSlide>
                <LiveTv
                  background={background}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TvMovies
                  background={background}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              </SwiperSlide>
              <SwiperSlide>
                <TvSettings
                  background={background}
                  primaryColor={primaryColor}
                  secondaryColor={secondaryColor}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </PageComponent>
    </div>
  );
}
