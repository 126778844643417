import React from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedLanguage } from "../store/features/profileSlice";

import { languageOptions } from "../config/data";
import "../styles/languages.scss";

export default function Languages() {
  const dispatch = useDispatch();
  const { selectedLanguage } = useSelector((state) => state.profile);

  const changeLanguage = (leng) => {
    dispatch(setSelectedLanguage(leng));
    localStorage.setItem("leng", leng);
  };

  return (
    <div className="languages-select">
      <Select
        onChange={(lng) => changeLanguage(lng)}
        optionLabelProp="label"
        className="language-select"
        value={selectedLanguage}
        showArrow={false}
        placement="bottomRight"
      >
        {languageOptions.map((lang) => (
          <Select.Option
            key={lang.value}
            value={lang.value}
            label={lang.label}
          >
            {lang.item}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}
