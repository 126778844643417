import { Drawer } from "antd";
import React from "react";
import useTranslate from "../../hooks/translator";
import { DeviceConfig, DeviceConfigInfo } from "../../components";

export default function DeviceConfigDrawer({ open, onClose }) {
  const translate = useTranslate();

  return (
    <Drawer
      forceRender
      open={open}
      onClose={onClose}
      placement="right"
      title={
        <>
          {translate("Device Config")}
          <DeviceConfigInfo />
        </>
      }
    >
      <DeviceConfig showTitle={false} onClose={onClose}/>
    </Drawer>
  );
}
