import LOGO from "../images/logo.png";

import TV_BACKGROUND from "../images/brending_page/bg.png";
import TV_LOGO from "../images/brending_page/logo.png";

import CREDIT_BTN from "../images/Credit.png";

import MOVIE_1 from "../images/brending_page/img/films/Green_Book.jpg";
import MOVIE_2 from "../images/brending_page/img/films/2.Avatar.jpeg";
import MOVIE_3 from "../images/brending_page/img/films/3.Black Widow.jpg";
import MOVIE_4 from "../images/brending_page/img/films/4.Avengers.jpg";
import MOVIE_5 from "../images/brending_page/img/films/5.Spider-Man.jpg";
import MOVIE_6 from "../images/brending_page/img/films/no film.png";
import MOVIE_7 from "../images/brending_page/img/films/film.jpg";
import MOVIE_8 from "../images/brending_page/img/films/Old Guard.png";
import MOVIE_9 from "../images/brending_page/img/films/no film.png";
import MOVIE_10 from "../images/brending_page/img/films/Red notice.jpg";
import MOVIE_11 from "../images/brending_page/img/films/Bohemian Rapsody.png";
import MOVIE_12 from "../images/brending_page/img/films/Star Is Born.png";
import MOVIE_13 from "../images/brending_page/img/films/6.Bullet Train.jpg";
import MOVIE_14 from "../images/brending_page/img/films/Ambulance.png";
import MOVIE_15 from "../images/brending_page/img/films/Old Resort.jpg";

import CHANNEL_4 from "../images/brending_page/img/channels/BBC-News.jpg";
import CHANNEL_2 from "../images/brending_page/img/channels/channel_2.jpg";
import CHANNEL_3 from "../images/brending_page/img/channels/channel_3.jpg";
import CHANNEL_1 from "../images/brending_page/img/channels/4.CNN_International.svg.png";
import CHANNEL_5 from "../images/brending_page/img/channels/5.Fox TV.png";
import CHANNEL_6 from "../images/brending_page/img/channels/6.CCTV News.png";
import CHANNEL_7 from "../images/brending_page/img/channels/7.CBS News.jpg";

// social icons
import MESSAGE from "../images/message.png";
import JOIN_COMMUNITY from "../images/add-group.png";

// languages
import EN from "../images/flags/en.png";
import PT from "../images/flags/pt.png";
import DE from "../images/flags/de.png";
import ES from "../images/flags/es.png";
import FR from "../images/flags/fr.png";
import IT from "../images/flags/it.png";
import SQ from "../images/flags/sq.png";

// onError
import MAN from "../images/man.png";
import ERROR from "../images/err.png";
import LANGUAGE_ICON from "../images/language_icon.png";
import NO_POSTER from "../images/img_onError.jpg";

import MASTER_CARD from "../images/visa-master.png";
import PAY_WITH_CARD from "../images/pay_with_card.png";

import VPN from "../images/vpn.png";

import SUBTRACT from "../images/subtract.png";

import GUIDE_SECTOR from "../images/guide-sector.jpg";

//platforms
import PLATFORM_BG from "../images/platforms/platform-bg.png";
import ANDROID_TV from "../images/platforms/androidTv.svg";
import TIZEN from "../images/platforms/tizen.svg";
import WEBOS from "../images/platforms/webos.svg";
import FIRE_TV from "../images/platforms/fireTv.svg";
import TV_OS from "../images/platforms/tvos.svg";
import ROKU from "../images/platforms/roku.svg";
import VIDDA from "../images/platforms/vidda.svg";
import FOXXUM from "../images/platforms/foxxum.svg";
import ZESSEN from "../images/platforms/zeas.svg";
import VIZIO from "../images/platforms/vizio.svg";
import IOS from "../images/platforms/ios.svg";
import ANDROID_MOBILE from "../images/platforms/android-mobile.svg";

// app branding backgrounds
import BRANDING_BG_1 from "../images/brending_page/app-background/1.png";
import BRANDING_BG_2 from "../images/brending_page/app-background/2.png";
import BRANDING_BG_4 from "../images/brending_page/app-background/4.png";
import BRANDING_BG_5 from "../images/brending_page/app-background/5.png";
import BRANDING_BG_6 from "../images/brending_page/app-background/6.png";
import BRANDING_BG_7 from "../images/brending_page/app-background/7.png";
import BRANDING_BG_8 from "../images/brending_page/app-background/8.png";
import BRANDING_BG_9 from "../images/brending_page/app-background/9.png";
import BRANDING_BG_10 from "../images/brending_page/app-background/10.png";
import BRANDING_BG_11 from "../images/brending_page/app-background/11.png";
import BRANDING_BG_12 from "../images/brending_page/app-background/12.png";
import BRANDING_BG_13 from "../images/brending_page/app-background/13.png";
import BRANDING_BG_15 from "../images/brending_page/app-background/15.png";

const IMAGES = {
  // a
  ANDROID_TV,
  ANDROID_MOBILE,
  // b
  BRANDING_BG_1,
  BRANDING_BG_2,
  BRANDING_BG_4,
  BRANDING_BG_5,
  BRANDING_BG_6,
  BRANDING_BG_7,
  BRANDING_BG_8,
  BRANDING_BG_9,
  BRANDING_BG_10,
  BRANDING_BG_11,
  BRANDING_BG_12,
  BRANDING_BG_13,
  BRANDING_BG_15,
  // c
  CHANNEL_1,
  CHANNEL_2,
  CHANNEL_3,
  CHANNEL_4,
  CHANNEL_5,
  CHANNEL_6,
  CHANNEL_7,
  CREDIT_BTN,
  // d
  DE,
  // e
  EN,
  ES,
  ERROR,
  // f
  FR,
  FIRE_TV,
  FOXXUM,
  // g
  GUIDE_SECTOR,
  // h
  // i
  IT,
  IOS,
  // j
  JOIN_COMMUNITY,
  // k
  // l
  LOGO,
  LANGUAGE_ICON,
  // m
  MASTER_CARD,
  MAN,
  MOVIE_1,
  MOVIE_2,
  MOVIE_3,
  MOVIE_4,
  MOVIE_5,
  MOVIE_6,
  MOVIE_7,
  MOVIE_8,
  MOVIE_9,
  MOVIE_10,
  MOVIE_11,
  MOVIE_12,
  MOVIE_13,
  MOVIE_14,
  MOVIE_15,
  MESSAGE,
  // n
  NO_POSTER,
  // o
  // p
  PT,
  PAY_WITH_CARD,
  PLATFORM_BG,
  // q
  // r
  ROKU,
  // s
  SUBTRACT,
  SQ,
  // t
  TV_BACKGROUND,
  TV_LOGO,
  TIZEN,
  TV_OS,
  // u
  // v
  VPN,
  VIDDA,
  VIZIO,
  // w
  WEBOS,
  // x
  // y
  // z
  ZESSEN,
};

export default IMAGES;
