import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { Table, Tooltip, Button, Tag, message, Modal, Space } from "antd";

import { ExclamationCircleFilled, InfoCircleOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ICONS, IMAGES } from "../../config";
import {
  getColumnSearchProps,
  getQueryDate,
  getColumnDateProps,
} from "../../config/filters";
import { parseFullDate } from "../../config/formats";

import {
  Tags,
  Check,
  ActionTable,
  confirmModal,
  LongText,
  CopyText,
} from "../../components";

import DevicesDrawer from "./DevicesDrawer";

export default function AllDevices({
  activeTab,
  isDrawerOpen,
  setIsDrawerOpen,
  getDeviceInfo,
  getProfile,
  platforms,
  selected,
  setSelected,
  myActivate,
  setMyActivate,
}) {
  const navigate = useNavigate();
  const translate = useTranslate();

  const { profile } = useSelector((store) => store.profile);

  const canUseFree =
    profile?.parent_id && profile?.status === "CAN_USE_FREE_PARTNER_CREDITS";

  const [loading, setLoading] = useState(false);

  const [devices, setDevices] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState(["createdAt", "DESC"]);
  const [sortedInfo, setSortedInfo] = useState({});

  const [search, setSearch] = useState({
    appName: null,
    deviceKey: null,
    country: null,
    playlist: null,
    appVersion: null,
    model: null,
    browserVersion: null,
  });

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    updatedAt: null,
    expired: null,
    freeTrialExpired: null,
    platform: null,
    autoRenew: null,
    isActive: null,
    activatedBy: null,
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeTab == "AllDevices") {
        getDevices();
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, filtersTb, currentPage, limit, sort, activeTab]);

  const checkPlaylistForError = (playlist_status) => {
    switch (playlist_status) {
      case "wrong":
        return translate(
          "Please check the playlist as it is incorrectly filled in."
        );

      case "expired":
        return translate(
          "Please check this playlist as its expiration date has expired."
        );
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      render: (text, record, index) => limit * (currentPage - 1) + index + 1,
    },

    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record.device_key ? <CopyText text={record.device_key} /> : "N/A",
    },

    {
      title: translate("Platform"),
      key: "platform name",
      align: "center",
      filters: platforms,
      render: (text, record, index) => {
        return (
          (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Tooltip
                title={
                  record?.platform?.name == "Antroid Mobile"
                    ? "Android Mobile"
                    : record?.platform?.name
                }
              >
                <img
                  src={record?.platform?.icon}
                  style={{
                    width: "37px",
                    height: "23px",
                    marginRight: "10px",
                    objectFit: "contain",
                  }}
                  alt="platform"
                  onError={(e) => (e.target.src = IMAGES.ERROR)}
                />
              </Tooltip>
            </div>
          ) || <Tags />
        );
      },
    },

    {
      title: translate("Status"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      filters: [
        {
          text: translate("Trial"),
          value: "is_trial",
        },
        {
          text: translate("Active"),
          value: true,
        },
        {
          text: translate("Deactive"),
          value: false,
        },
      ],
      filterMultiple: false,
      render: (text, record, index) =>
        record.is_active ? (
          <Tag color="green">{translate("Active")}</Tag>
        ) : !record.is_active && record.is_trial ? (
          <Tag color="gold">{translate("Trial")}</Tag>
        ) : (
          <Tag color="red">{translate("Deactive")}</Tag>
        ),
    },
    {
      title: translate("Playlist"),
      dataIndex: "playlist",
      key: "playlist",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) => {
        const playlistStatus = record?.playlist_status?.toLowerCase();

        if (!record?.playlist) {
          return "N/A";
        } else if (playlistStatus === "wrong" || playlistStatus === "expired") {
          return (
            <>
              <Tooltip title={checkPlaylistForError(playlistStatus)}>
                <Button
                  icon={<InfoCircleOutlined />}
                  type="link"
                  style={{ color: "#cf1222" }}
                />
              </Tooltip>
              <Space>
                <CopyText
                  text={
                    <LongText
                      children={record?.playlist}
                      style={{ color: "#cf1222" }}
                    />
                  }
                  style={{ color: "#cf1222" }}
                />
              </Space>
            </>
          );
        } else {
          return <CopyText text={<LongText children={record?.playlist} />} />;
        }
      },
    },
    {
      title: translate("App name"),
      key: "app_name",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) => (
        <div>{record?.app_name ? record?.app_name : "N/A"}</div>
      ),
    },
    {
      title: translate("Country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      ...getColumnSearchProps(),

      render: (text, record, index) =>
        record?.country ? record?.country : "N/A",
    },
    {
      title: translate("IP address"),
      dataIndex: "ip_address",
      key: "ip_address",
      align: "center",
      ...getColumnSearchProps(),

      render: (text, record, index) =>
        record?.ip_address ? (
          <CopyText text={<LongText children={record?.ip_address} />} />
        ) : (
          "N/A"
        ),
    },
    {
      title: translate("Activated by"),
      dataIndex: "activated_by",
      key: "activated_by",
      align: "center",
      filters: [
        {
          text: translate("Device"),
          value: "device",
        },
        {
          text: translate("Provider"),
          value: "provider",
        },
      ],
      render: (text, record, index) =>
        record?.activated_by == "device"
          ? translate("Device")
          : translate("Provider"),
    },

    {
      title: translate("Auto renew"),
      dataIndex: "auto_renew",
      key: "auto_renew",
      align: "center",
      filters: [
        {
          text: "YES",
          value: true,
        },
        {
          text: "NO",
          value: false,
        },
      ],
      render: (text, record, index) => <Check check={record.auto_renew} />,
    },

    {
      title: translate("Updated date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "updatedAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, updatedAt: dateString })
      ),
      render: (text, record, index) => (text ? parseFullDate(text) : "N/A"),
    },

    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => (text ? parseFullDate(text) : "N/A"),
    },

    {
      title: translate("Expired date"),
      dataIndex: "expired_date",
      key: "expired_date",
      align: "center",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "expired_date" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, expired: dateString })
      ),
      render: (text, record, index) => (text ? parseFullDate(text) : "N/A"),
    },

    {
      title: translate("Free trial expired"),
      dataIndex: "free_trial_expired",
      key: "free_trial_expired",
      align: "center",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "free_trial_expired" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, freeTrialExpired: dateString })
      ),
      render: (text, record, index) => (text ? parseFullDate(text) : "N/A"),
    },
    {
      title: translate("App version"),
      dataIndex: "app_version",
      key: "app_version",
      align: "center",
      sortOrder:
        sortedInfo.columnKey === "app_version" ? sortedInfo.order : null,
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record?.app_version ? record?.app_version : "N/A",
    },
    {
      title: translate("Model"),
      dataIndex: "model",
      key: "model",
      align: "center",
      sortOrder: sortedInfo.columnKey === "model" ? sortedInfo.order : null,
      ...getColumnSearchProps(),
      render: (text, record, index) => (record?.model ? record?.model : "N/A"),
    },
    {
      title: translate("Browser version"),
      dataIndex: "browser_version",
      key: "browser_version",
      align: "center",
      sortOrder:
        sortedInfo.columnKey === "browser_version" ? sortedInfo.order : null,
      ...getColumnSearchProps(),
      render: (text, record, index) =>
        record?.browser_version ? record?.browser_version : "N/A",
    },
    profile?.sync_playlist && (profile?.is_partner || canUseFree)
      ? {
          title: translate("Sync playlist"),
          key: "sync__actions",
          align: "center",
          render: (text, record, index) =>
            !record.is_active && !record.is_trial ? (
              <Tooltip title={translate("Deactive device")}>
                <div className="disabled-txt">{ICONS.SYNC}</div>
              </Tooltip>
            ) : (
              <Tooltip title={translate("Sync playlist")}>
                <div
                  className="sync-playlist"
                  onClick={() => syncPlaylist(record)}
                >
                  {" "}
                  {ICONS.SYNC}{" "}
                </div>
              </Tooltip>
            ),
        }
      : {},
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <ActionTable
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "info",
              label: translate("Info"),
              icon: ICONS.INFO_I,
            },
            (record.is_active || record.is_trial) && {
              key: "edit",
              label: translate("Edit"),
              icon: ICONS.EDIT,
            },
            (record.is_active || record.is_trial) && {
              key: "deactivate",
              label: translate("Deactivate"),
              icon: ICONS.FALSE,
            },
            !record.is_active && {
              key: "activate",
              label: translate("Activate"),
              icon: ICONS.TRUE,
            },
            {
              key: "block",
              label: translate("Block"),
              icon: ICONS.BLOCK,
            },
          ]}
        />
      ),
    },
  ];

  const onSyncPlaylist = (item) => {
    let txt = item?.playlist;

    // Extract value between "username=" and "&"
    let usernameMatch = txt.match(/username=([^&]*)/);
    let usernameValue = usernameMatch ? usernameMatch[1] : null;

    // Extract value after "password="
    let passwordMatch = txt.match(/password=([^&]*)/);
    let passwordValue = passwordMatch ? passwordMatch[1] : null;

    const body = {
      device_id: item?.id,
      username: usernameValue && usernameValue,
      password: passwordValue && passwordValue,
    };

    if (usernameValue && passwordValue) {
      REQUESTS.SYNC_PLAYLIST(body)
        .then((res) => {
          message.success(res);
          getDevices();
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const syncPlaylist = (item) => {
    Modal.confirm({
      title: translate("Sync playlist"),
      icon: <ExclamationCircleFilled />,
      content: translate(
        `You can sync playlist by clicking the "Sync" button or wait for the device to be logged in, after which it will sync automatically.`
      ),
      onOk() {
        onSyncPlaylist(item);
      },
      onCancel() {},
    });
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "info":
        navigate(`/dashboard/device?key=${item.device_key}`);
        break;
      case "edit":
        setSelected(item);
        setIsDrawerOpen(true);
        setMyActivate(false);
        break;
      case "activate":
        setSelected(item);
        setMyActivate(true);
        setIsDrawerOpen(true);
        break;

      case "deactivate":
        confirmModal({
          title:
            profile?.is_partner || profile?.parent_id
              ? translate("Do you want to deactivate this device")
              : translate("device activation info"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => deactivateItem(item),
        });
        break;
      case "block":
        confirmModal({
          title: translate("Do you want to blocked this device"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => {
            REQUESTS.DEVICE_BLACK_LIST.POST({ device_id: item.id })
              .then((res) => {
                message.success(res);
                getProfile();
                getDevices();
                getDeviceInfo();
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
      default:
        break;
    }
  };

  function deactivateItem(item) {
    REQUESTS.DEACTIVATE_DEVICE({ id: item?.id })
      .then((res) => {
        message.success(res);
        getProfile();
        getDevices();
        getDeviceInfo();
      })
      .catch((err) => {
        message.error(err);
      });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      platform: filters["platform name"] || null,
      autoRenew: filters["auto_renew"] || null,
      activatedBy: filters["activated_by"] || null,
      isActive: filters["is_active"] || null,
    }));

    setSearch((prevSearch) => ({
      ...prevSearch,
      appName: filters["app_name"] || null,
      deviceKey: filters["device_key"] || null,
      ipAddress: filters["ip_address"] || null,
      country: filters["country"] || null,
      playlist: filters["playlist"] || null,
      appVersion: filters["app_version"] || null,
      model: filters["model"] || null,
      browserVersion: filters["browser_version"] || null,
    }));

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getDevices = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }
      if (search.deviceKey) {
        query.search["device_key"] = search.deviceKey[0];
      }
      if (search.ipAddress) {
        query.search["ip_address"] = search.ipAddress[0];
      }
      if (search.appName) {
        query.search["app_name"] = search.appName[0];
      }
      if (search.country) {
        query.search["country"] = search.country[0];
      }
      if (search.playlist) {
        query.search["playlist"] = search.playlist[0];
      }
      if (search.appVersion) {
        query.search["app_version"] = search.appVersion[0];
      }
      if (search.model) {
        query.search["model"] = search.model[0];
      }
      if (search.browserVersion) {
        query.search["browser_version"] = search.browserVersion[0];
      }
    } else {
      delete query.search;
    }

    //date filters
    const queryDate = getQueryDate(filtersTb.date);

    const queryUpdatedAt = getQueryDate(filtersTb.updatedAt);

    const queryExpired = getQueryDate(filtersTb.expired);

    const queryFreeTrialExpired = getQueryDate(filtersTb.freeTrialExpired);

    if (queryDate) {
      query.between = queryDate;
    }

    if (queryUpdatedAt) {
      query.between = queryUpdatedAt;
    }

    if (queryExpired) {
      query.between = queryExpired;
    }

    if (queryFreeTrialExpired) {
      query.between = queryFreeTrialExpired;
    }

    if (
      filtersTb.platform ||
      filtersTb.autoRenew ||
      filtersTb.activatedBy ||
      filtersTb.isActive
    ) {
      query["filter"] = {};

      if (filtersTb.platform) {
        query["filter"]["platform_id"] = filtersTb.platform;
      }

      if (filtersTb.autoRenew) {
        query["filter"]["auto_renew"] = filtersTb.autoRenew;
      }

      if (filtersTb.activatedBy) {
        query["filter"]["activated_by"] = filtersTb.activatedBy;
      }
      if (filtersTb.isActive) {
        switch (filtersTb.isActive[0]) {
          case "is_trial":
            query["filter"]["is_trial"] = [true];
            break;
          default:
            query["filter"]["is_active"] = filtersTb.isActive;
            query["filter"]["is_trial"] = [false];
            break;
        }
      }
    } else {
      delete query.filter;
    }

    REQUESTS.DEVICES({ query: JSON.stringify(query) })
      .then((data) => {
        const devicesList = data?.rows?.filter((el) => el.black_list == null);
        setLoading(false);
        setDevices(devicesList);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const rowClassName = (record, index) => {
    if (record.is_active) {
      return "green-row";
    } else if (!record.is_active && record.is_trial) {
      return "gold-row";
    } else {
      return "red-row";
    }
  };

  return (
    <>
      <Table
        rowKey="id"
        size={"small"}
        onChange={handleTableChange}
        dataSource={devices}
        columns={columns}
        loading={loading}
        rowClassName={rowClassName}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{
          x: "max-content",
          y: null,
        }}
      />

      {activeTab == "AllDevices" && (
        <DevicesDrawer
          open={isDrawerOpen}
          setOpen={() => setIsDrawerOpen(false)}
          getData={getDevices}
          getDeviceInfo={getDeviceInfo}
          selected={selected}
          myActivate={myActivate}
        />
      )}
    </>
  );
}
