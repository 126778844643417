import IMAGES from "./images";
import tv from "../images/brending_page/tv.png";
import movies from "../images/brending_page/movies.png";
import series from "../images/brending_page/series.png";
import settings from "../images/brending_page/settings.png";

export const APP_BRANDING_BACKGROUNDS = [
  {
    id: 1,
    src: IMAGES.BRANDING_BG_1,
  },
  {
    id: 2,
    src: IMAGES.BRANDING_BG_2,
  },

  {
    id: 3,
    src: IMAGES.BRANDING_BG_4,
  },
  {
    id: 4,
    src: IMAGES.BRANDING_BG_5,
  },
  {
    id: 5,
    src: IMAGES.BRANDING_BG_6,
  },
  {
    id: 6,
    src: IMAGES.BRANDING_BG_7,
  },
  {
    id: 7,
    src: IMAGES.BRANDING_BG_8,
  },
  {
    id: 8,
    src: IMAGES.BRANDING_BG_9,
  },
  {
    id: 9,
    src: IMAGES.BRANDING_BG_10,
  },
  {
    id: 10,
    src: IMAGES.BRANDING_BG_11,
  },
  {
    id: 11,
    src: IMAGES.BRANDING_BG_12,
  },
  {
    id: 12,
    src: IMAGES.BRANDING_BG_13,
  },

  {
    id: 14,
    src: IMAGES.BRANDING_BG_15,
  },
];

export const BRANDING_TV_MOVIES_CATEGORIES = [
  {
    id: 1,
    name: "ROMANCE",
  },
  {
    id: 2,
    name: "PORTUGUESE...",
  },
  {
    id: 3,
    name: "DRAME",
    lock: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        viewBox="0 -960 960 960"
        width="32"
        fill="currentColor"
      >
        <path d="M220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h70v-96q0-78.85 55.606-134.425Q401.212-920 480.106-920T614.5-864.425Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350-634h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730v96ZM220-140v-434 434Z" />
      </svg>
    ),
  },
  {
    id: 4,
    name: "COMEDY",
  },
  {
    id: 5,
    name: "VOD-THRILLER",
  },
  {
    id: 6,
    name: "DOCUMENTAR",
  },
  {
    id: 7,
    name: "NACIONALS",
  },
];

export const BRANDING_TV_MOVIES = [
  {
    id: 1,
    poster: IMAGES.MOVIE_1,
    name: "Green Book",
    rating: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
      >
        <path
          d="M11.3619 1.9165C12.3004 1.9165 13.7682 6.12856 14.3211 7.8243C14.4562 8.23873 14.8349 8.52305 15.2705 8.53839C17.0396 8.60068 21.4167 8.85587 21.4167 9.85615C21.4167 10.8408 18.3063 13.2978 16.9716 14.3067C16.6192 14.5731 16.4731 15.0331 16.6083 15.4537C17.1499 17.138 18.3865 21.3103 17.5774 21.9746C16.782 22.6275 13.4179 20.0882 11.9845 18.9463C11.6048 18.6438 11.0616 18.6434 10.6817 18.9457C9.24737 20.0874 5.88541 22.6276 5.14643 21.9746C4.39339 21.309 5.56584 17.1222 6.07473 15.4442C6.20101 15.0278 6.05385 14.5778 5.70668 14.3155C4.37858 13.3121 1.25 10.8437 1.25 9.85615C1.25 8.85473 5.63698 8.6001 7.40217 8.53817C7.83489 8.52299 8.21173 8.24235 8.3491 7.83173C8.91476 6.14091 10.4221 1.9165 11.3619 1.9165Z"
          fill="#3EFF0E"
          stroke="#3EFF0E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 2,
    poster: IMAGES.MOVIE_2,
    name: "Avatar",
    rating: "",
    favorit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99963 19.348L8.54963 18.028C3.39963 13.358 -0.000366211 10.268 -0.000366211 6.49805C-0.000366211 3.40805 2.41963 0.998047 5.49963 0.998047C7.23963 0.998047 8.90963 1.80805 9.99963 3.07805C11.0896 1.80805 12.7596 0.998047 14.4996 0.998047C17.5796 0.998047 19.9996 3.40805 19.9996 6.49805C19.9996 10.268 16.5996 13.358 11.4496 18.028L9.99963 19.348Z"
          fill="#3EFF0E"
        />
      </svg>
    ),
  },
  {
    id: 3,
    poster: IMAGES.MOVIE_3,
    name: "Black Widow",
    rating: "",
  },
  {
    id: 4,
    poster: IMAGES.MOVIE_4,
    name: "Avengers",
    rating: "",
  },
  {
    id: 5,
    poster: IMAGES.MOVIE_5,
    name: "Spider-Man",
    rating: "",
  },
  {
    id: 6,
    poster: IMAGES.MOVIE_6,
    name: "Avengers",
    rating: "",
    lock: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        viewBox="0 -960 960 960"
        width="32"
        fill="currentColor"
      >
        <path d="M220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h70v-96q0-78.85 55.606-134.425Q401.212-920 480.106-920T614.5-864.425Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350-634h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730v96ZM220-140v-434 434Z" />
      </svg>
    ),
  },
  {
    id: 7,
    poster: IMAGES.MOVIE_7,
    name: "Gravity",
    rating: "",
  },
  {
    id: 8,
    poster: IMAGES.MOVIE_8,
    name: "Old Guard",
    rating: "",
  },
];

export const BRANDING_TV_ICONS = [
  { id: 1, icon: tv, name: "Live TV" },
  { id: 2, icon: movies, name: "Movies" },
  { id: 3, icon: series, name: "Series" },
  { id: 4, icon: settings, name: "Settings" },
];

export const BRANDING_LIVE_TV_CATEGORIES = [
  {
    id: 1,
    name: "S",
  },
  {
    id: 2,
    name: "F",
  },
  {
    id: 3,
    name: "A",
  },
  {
    id: 4,
    name: "C",
  },
  {
    id: 5,
    name: "L",
  },
  {
    id: 6,
    name: "M",
  },
  {
    id: 7,
    name: "C",
  },
];

export const BRANDING_LIVE_TV = [
  {
    id: 1,
    icon: IMAGES.CHANNEL_1,
    name: "DE:Sky Thriller HD+",
    favorit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99963 19.348L8.54963 18.028C3.39963 13.358 -0.000366211 10.268 -0.000366211 6.49805C-0.000366211 3.40805 2.41963 0.998047 5.49963 0.998047C7.23963 0.998047 8.90963 1.80805 9.99963 3.07805C11.0896 1.80805 12.7596 0.998047 14.4996 0.998047C17.5796 0.998047 19.9996 3.40805 19.9996 6.49805C19.9996 10.268 16.5996 13.358 11.4496 18.028L9.99963 19.348Z"
          fill="#3EFF0E"
        />
      </svg>
    ),
  },
  {
    id: 2,
    icon: IMAGES.CHANNEL_2,
    name: "DE:Sky Thriller HD",
    lock: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        viewBox="0 -960 960 960"
        width="32"
        fill="currentColor"
      >
        <path d="M220-80q-24.75 0-42.375-17.625T160-140v-434q0-24.75 17.625-42.375T220-634h70v-96q0-78.85 55.606-134.425Q401.212-920 480.106-920T614.5-864.425Q670-808.85 670-730v96h70q24.75 0 42.375 17.625T800-574v434q0 24.75-17.625 42.375T740-80H220Zm0-60h520v-434H220v434Zm260.168-140Q512-280 534.5-302.031T557-355q0-30-22.668-54.5t-54.5-24.5Q448-434 425.5-409.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350-634h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426-860 388-822.083 350-784.167 350-730v96ZM220-140v-434 434Z" />
      </svg>
    ),
  },
  {
    id: 3,
    icon: IMAGES.CHANNEL_3,
    name: "DE:Sky Premieren HD+",
  },
  {
    id: 4,
    icon: IMAGES.CHANNEL_4,
    name: "DE:Sky Premieren HD",
    favorit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99963 19.348L8.54963 18.028C3.39963 13.358 -0.000366211 10.268 -0.000366211 6.49805C-0.000366211 3.40805 2.41963 0.998047 5.49963 0.998047C7.23963 0.998047 8.90963 1.80805 9.99963 3.07805C11.0896 1.80805 12.7596 0.998047 14.4996 0.998047C17.5796 0.998047 19.9996 3.40805 19.9996 6.49805C19.9996 10.268 16.5996 13.358 11.4496 18.028L9.99963 19.348Z"
          fill="#3EFF0E"
        />
      </svg>
    ),
  },
  { id: 5, icon: IMAGES.CHANNEL_5, name: "DE:Sky Premieren 24 HD+" },
  {
    id: 6,
    icon: IMAGES.CHANNEL_6,
    name: "DE:Sky Premieren 24 HD",
  },
  {
    id: 7,
    icon: IMAGES.CHANNEL_7,
    name: "DE:Sky Action HD",
    favorit: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99963 19.348L8.54963 18.028C3.39963 13.358 -0.000366211 10.268 -0.000366211 6.49805C-0.000366211 3.40805 2.41963 0.998047 5.49963 0.998047C7.23963 0.998047 8.90963 1.80805 9.99963 3.07805C11.0896 1.80805 12.7596 0.998047 14.4996 0.998047C17.5796 0.998047 19.9996 3.40805 19.9996 6.49805C19.9996 10.268 16.5996 13.358 11.4496 18.028L9.99963 19.348Z"
          fill="#3EFF0E"
        />
      </svg>
    ),
  },
];

export const freeTrialOptions = [
  {
    value: 1,
    label: "1 days",
  },
  {
    value: 2,
    label: "2 days",
  },
  {
    value: 3,
    label: "3 days",
  },
  {
    value: 4,
    label: "4 days",
  },
  {
    value: 5,
    label: "5 days",
  },
  {
    value: 6,
    label: "6 days",
  },
  {
    value: 7,
    label: "7 days",
  },
];

export const appActivationTypeOptions = [
  {
    label: "Activate",
    value: "active",
  },
  {
    label: "Free trial",
    value: "free_trial",
  },
];

export const languageOptions = [
  {
    label: <img src={IMAGES.PT} alt="pt" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.PT} alt="pt" />
        {"Brazilian"}
      </span>
    ),
    value: "pt",
  },
  {
    label: <img src={IMAGES.EN} alt="en" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.EN} alt="en" width={"25px"} />
        {"English"}
      </span>
    ),
    value: "en",
  },
  {
    label: <img src={IMAGES.ES} alt="es" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.ES} alt="es" />
        {"Spanish"}
      </span>
    ),
    value: "es",
  },
  {
    label: <img src={IMAGES.IT} alt="it" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.IT} alt="it" />
        {"Italian"}
      </span>
    ),
    value: "it",
  },
  {
    label: <img src={IMAGES.SQ} alt="sq" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.SQ} alt="sq" />
        {"Albania"}
      </span>
    ),
    value: "sq",
  },
  {
    label: <img src={IMAGES.DE} alt="de" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.DE} alt="de" />
        {"German"}
      </span>
    ),
    value: "de",
  },

  {
    label: <img src={IMAGES.FR} alt="fr" />,
    item: (
      <span className="label-lang">
        <img src={IMAGES.FR} alt="fr" />
        {"French"}
      </span>
    ),
    value: "fr",
  },
];
